<template>
  <div>
    <div class="mb-1 block text-sm font-medium text-neutral-700">
      {{ label }}
    </div>
    <div
      v-for="item in selectedItems"
      :key="item.key"
      class="mb-2 flex items-center"
    >
      <div
        class="flex-grow border flex h-12 min-w-0 items-center rounded-sm border-neutral-100 bg-neutral-0 px-3 pr-4 text-sm shadow-sm"
      >
        <div class="truncate">
          {{ item.title }}
        </div>
        <div class="truncate ml-2 text-xs text-neutral-400">
          {{ item.subTitle }}
        </div>
      </div>
      <button
        class="focus:outline-none ml-2 flex-shrink-0 text-neutral-400 transition-all duration-200 hover:text-neutral-900"
        @click="removeItem(item.key)"
      >
        <IconClose />
      </button>
    </div>
    <div class="group relative">
      <IconPlus
        :class="[
          'pointer-events-none absolute mt-1 text-neutral-300 transition-all duration-200',
          { 'group-hover:text-neutral-600': !disabled },
        ]"
      />
      <select
        :class="[
          'focus:outline-none w-full appearance-none bg-transparent py-1 pl-6 text-sm text-neutral-600',
          { 'cursor-not-allowed': disabled },
          { 'cursor-pointer': !disabled },
        ]"
        :disabled="disabled"
        @change="onChange($event)"
      >
        <option value="-1" selected disabled>{{ addText }}</option>
        <option
          v-for="item in availableItems"
          :key="item.key"
          :value="item.key"
        >
          {{ `${item.title} - ${item.subTitle}` }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    selectedItemOptions: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: function () {
        return this.$t("components.common.listSelector.label");
      },
    },

    addText: {
      type: String,
      default: function () {
        return this.$t("components.common.listSelector.addText");
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedItems: this.selectedItemOptions,
      availableItems: [],
    };
  },

  watch: {
    itemOptions: function (newItemOptions) {
      this.availableItems = newItemOptions.filter((item) => {
        const selectedItemKeys = this.selectedItems.map((item) => item.key);
        return !selectedItemKeys.includes(item.key);
      });
    },
  },

  created() {
    this.availableItems = this.itemOptions.filter((item) => {
      const selectedItemKeys = this.selectedItems.map((item) => item.key);
      return !selectedItemKeys.includes(item.key);
    });
  },

  methods: {
    onChange(event) {
      const itemKey = event.target.value;
      const itemObject = this.availableItems.find(
        (item) => item.key === itemKey,
      );

      const selectedIndex = this.availableItems.findIndex(
        (item) => item.key === itemKey,
      );
      this.availableItems.splice(selectedIndex, 1);

      this.selectedItems.push(itemObject);
      event.target.value = -1;
      this.emitSelectedItems();
    },

    removeItem(itemKey) {
      const removeObject = this.selectedItems.find(
        (item) => item.key === itemKey,
      );
      const selectedIndex = this.selectedItems.indexOf(removeObject);

      this.availableItems.push(removeObject);
      this.selectedItems.splice(selectedIndex, 1);
      this.emitSelectedItems();
    },

    emitSelectedItems() {
      this.$emit("updateSelectedItems", this.selectedItems);
    },
  },
};
</script>
