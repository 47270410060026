<template>
  <ul>
    <li v-for="user in users" :key="user.uuid" class="flex items-center pt-5">
      <div class="pb-5 pr-3">
        <UserAvatar variant="primary" size="md" :user="user" />
      </div>
      <div class="border-b flex-grow border-neutral-50 pb-5">
        <div class="flex justify-between pr-5 md:pr-8">
          <div v-if="user.firstName && user.lastName" class="text-sm">
            {{ `${user.firstName} ${user.lastName}` }}
          </div>
          <div v-else class="text-sm">
            {{ $t("global.noNameFound") }}
          </div>
          <div class="text-2xs text-neutral-400">
            {{ user.role.description }}
          </div>
        </div>
        <div class="text-sm text-neutral-400">{{ user.email }}</div>
      </div>
    </li>
  </ul>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";

export default {
  components: {
    UserAvatar,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
};
</script>
