<template>
  <ul class="flex">
    <li v-for="user in firstUsers" :key="user.uuid" class="mr-2">
      <div class="group relative">
        <div
          v-if="user.firstName && user.lastName"
          class="whitespace-no-wrap invisible absolute bottom-full left-1/2 z-10 mb-2 -translate-x-1/2 transform rounded-sm bg-neutral-900 px-2 py-1 text-2xs text-white opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100"
        >
          <span
            class="absolute bottom-0 left-0 right-0 mx-auto -mb-1 h-2 w-2 rotate-45 transform bg-neutral-900"
          ></span>
          <span>{{ `${user.firstName} ${user.lastName}` }}</span>
        </div>
        <UserAvatar
          variant="primary"
          size="sm"
          :user="user"
          :has-crown="
            user.role.name === 'franchisee' ||
            user.role.name === 'masterFranchise'
          "
        />
      </div>
    </li>
    <li v-if="users.length > visibleUsers">
      <div
        class="border flex h-8 w-8 items-center justify-center rounded-sm border-neutral-50 bg-neutral-0 text-sm text-neutral-600"
      >
        <span>+{{ users.length - visibleUsers }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";

export default {
  components: {
    UserAvatar,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visibleUsers: 3,
    };
  },

  computed: {
    firstUsers() {
      return this.users ? this.users.slice(0, this.visibleUsers) : [];
    },
  },
};
</script>
